import React, {
	FC,
	useCallback,
	useContext,
	useEffect,
	useState,
	// , useState
} from 'react';
import {
	// Link,
	useNavigate,
} from 'react-router-dom';
// import PropTypes from 'prop-types';
import classNames from 'classnames';
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { useFormik } from 'formik';
import axios from 'axios';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import AuthContext from '../../../contexts/authContext';
import logo from '../../../assets/img/logo_apl_bjm1.png';
import Spinner from '../../../components/bootstrap/Spinner';
import Toasts from '../../../components/bootstrap/Toasts';
import { toast } from 'react-toastify';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = ({ isNewUser }) => {
	const companyName = process.env.REACT_APP_COMPANY_NAME;
	return (
		<>
			<div className='text-center h2 fw-bold mt-5'>
				Welcome,
				<br /> {companyName}
			</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

LoginHeader.defaultProps = {
	isNewUser: false,
};

const Login = () => {
	const { setUser } = useContext(AuthContext);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	const UrlBase = process.env.REACT_APP_BASE_API_URL;

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors: { loginUsername?: string; loginPassword?: string } = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Required';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: async (values) => {
			try {
				const response = await axios.post(`${UrlBase}login`, {
					email: values.loginUsername,
					password: values.loginPassword,
				});

				if (response.status === 200) {
					if (setUser) {
						setUser(values.loginUsername);
					}
					localStorage.setItem('token', response.data.token);
					localStorage.setItem('email-login', response.data.user.email);
					localStorage.setItem('permissions', JSON.stringify(response.data.permissions));
					handleOnClick();
					toast.success('Login success');
					// eslint-disable-next-line no-restricted-globals
					location.reload();
					setIsLoading(false);
				} else {
					toast.error(response.data?.message);
					setIsLoading(false);
					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			} catch (error) {
				console.log({ error });
				setIsLoading(false);
				toast.error('Username and password do not match.');
			}
		},
	});

	const login = useCallback(
		async (email: string, password: string) => {
			try {
				const response = await axios.post(`${UrlBase}login`, {
					email,
					password,
				});
				if (response.status === 200) {
					setIsLoading(false);
					if (setUser) {
						setUser(email);
					}
					localStorage.setItem('token', response.data.token);
					handleOnClick();
					// eslint-disable-next-line no-restricted-globals
					location.reload();
				} else {
					toast.error(response.data?.message);

					formik.setFieldError('loginPassword', 'Username and password do not match.');
				}
			} catch (error) {
				setIsLoading(false);
				toast.error('Username and password do not match.');
				console.log({ error });
			}
		},
		[UrlBase, setUser, formik, handleOnClick],
	);

	const getSessionSSO = useCallback(async () => {
		// try {
		// 	const response = await axios.get(`${UrlBase}sso-session`);
		// 	if (response.status === 200 && response.data.token) {
		// 		const { token } = response.data;
		// 		localStorage.setItem('token', token);
		// 		handleOnClick();
		// 		location.reload();
		// 	} else {
		// 		console.error('Failed to get SSO session');
		// 	}
		// } catch (error) {
		// 	console.error('Get SSO session error:', error);
		// }
	}, [UrlBase, handleOnClick]);

	useEffect(() => {
		if (localStorage.getItem('token')) handleOnClick();
	}, []);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const idParam = urlParams.get('id');

		if (idParam) {
			const decodedId = atob(idParam);

			// Assuming decodedId is in the format email:admin@database.com,password:123456
			const keyValuePairs = decodedId.split(',');

			// Create an object from the key-value pairs
			const credentials: Record<string, string> = {};
			keyValuePairs.forEach((pair) => {
				const [key, value] = pair.split(':');
				credentials[key] = value;
			});

			// Extract email and password from the object
			const { email, password } = credentials;

			// Call login API with the decoded credentials
			login(email, password);
		}
		const intervalId = setInterval(getSessionSSO, 3000);
		return () => clearInterval(intervalId);
	}, [login, getSessionSSO]);

	const handleSSOLogin = useCallback(async () => {
		// try {

		// 	const response = await axios.get(`${UrlBase}authorization`);

		// 	if (response.status === 200) {

		// 		const { message } = response.data;

		// 		const ssoUrl = message.includes('&client_id=sop-buntut-api')
		// 			? message
		// 			: `${message}&client_id=sop-buntut-api`;

		// 		console.log(ssoUrl)

		// 		window.location.href = ssoUrl;
		// 	} else {
		// 		console.error('Authorization failed');

		// 	}

		// } catch (error) {

		// 	console.error('SSO login error:', error);

		// }

		window.location.href = 'https://backend.sopbuntutksopbjm.com/api/loginviasso';
	}, [UrlBase]);

	return (
		<PageWrapper isProtected={false} title='Login' className={classNames({ 'bg-dark': true })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<img
										src={logo}
										alt='Logo'
										className='logo'
										width={150}
										hidden
									/>
								</div>
								<LoginHeader />
								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='loginUsername'
											isFloating
											label='Your email or username'
											// className={classNames({
											// 	'd-none': signInPassword,
											// })}
										>
											<Input
												autoComplete='username'
												value={formik.values.loginUsername}
												isTouched={formik.touched.loginUsername}
												invalidFeedback={formik.errors.loginUsername}
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												onFocus={() => {
													formik.setErrors({});
												}}
											/>
										</FormGroup>
										<br />
										<FormGroup id='loginPassword' isFloating label='Password'>
											<Input
												type='password'
												autoComplete='current-password'
												value={formik.values.loginPassword}
												isTouched={formik.touched.loginPassword}
												invalidFeedback={formik.errors.loginPassword}
												validFeedback='Looks good!'
												isValid={formik.isValid}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
											/>
										</FormGroup>
									</div>
									<div className='col-12 mb-3'>
										<Button
											color='warning'
											className='w-100 py-3'
											onClick={() => {
												setIsLoading(true);
												formik.handleSubmit();
											}}>
											{isLoading && (
												<span className='me-2'>
													<Spinner isSmall={true} />
												</span>
											)}
											Login
										</Button>
									</div>
								</form>
								{/* <div className='row mt-3'>
									<div className='col-12'>
										<div className='text-center'>
											<Button
												style={{
													backgroundColor: '#808080',
													color: '#fff',
												}}
												className='w-75 py-3'
												onClick={handleSSOLogin}>
												Login via SSO
											</Button>
										</div>
									</div>
								</div> */}
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
